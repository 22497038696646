.budget-cont{
  font-size: 5.2vw;
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 10vh;
  font-weight: 100;
}

.RED-desc{
margin-left: 15%;
margin-right: 15%;
margin-top: 10vh;
line-height: 30px;
}

@media screen and (orientation: landscape){
    .budget-cont{
        margin-bottom: 10vw;
    }
    .RED-desc{
        margin-top: 10vw;
    }
}

@media screen and (max-width: 750px){
    .budget-cont{
        font-size: 39px;
    }
    .RED-desc{
        font-weight: 100;
    }
}
@media screen and (min-width: 1000px){
    .budget-cont{
        font-size: 40px;
        margin-right: 25%;
        margin-left: 25%;
    }
    .RED-desc{
        margin-left: 25%;
        margin-right: 25%;
    }
}

@media screen and (min-width: 1300px){
    .budget-cont{
        font-size: 52px;
        margin-left: 32%;
        margin-right: 32%;
    }
    .RED-desc{
        margin-left: 32%;
        margin-right: 32%;
    }
}

@media screen and (min-width: 1300px){
    .budget-cont{
        margin-bottom: 10vh;
    }
    .RED-desc{
        margin-top: 10vh;
    }
}

.residential-cont{
    position: absolute;
    font-size: 6.5vw;
    color: white;
    width: 50vw;
    background-color: rgba(0,0,0,0.6);
    text-align: center;
    margin-left: 22vw;
    margin-top: 30vh;

}
.residential-img{
    width: 100%;
    height: 500px;
    background-image: url('../../../public/residential.webp');
    background-attachment: fixed;
    margin-top: 10vh;
}

@media screen and (orientation: landscape){
    .residential-img{
        margin-top: 10vw;
    }
}
  
@media screen and (max-width: 750px){
    .residential-cont{
        font-size: 35px;
        width: 325px;
        margin-left: 7vw;
        margin-top: 50vw;
    }
}

@media screen and (max-width: 750px) and (orientation: landscape){
    .residential-cont{
        margin-left: 27vw;
        margin-top: 35vw;
    }
  }

@media screen and (min-width: 1000px){
    .residential-cont{
        font-size: 50px;
        width: 400px;
        margin-left: 31vw;
        margin-top: 36vh;
    }
}

@media screen and (min-width: 1300px){
    .residential-img{
        width: 60vw;
        margin-left: 20vw;
    }
    .residential-cont{
        font-size: 65px;
        width: 500px;
        margin-left: 25vw;
    }
}

@media screen and (min-width: 1300px){
    .residential-img{
        margin-top: 10vh;
    }
}

.services-cont{
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 10vh;
    margin-bottom: 10vh;
    text-align: center;
}

@media screen and (orientation: landscape){
    .services-cont{
        margin-top: 10vw;
        margin-bottom: 10vw;
    }
}

@media screen and (min-width: 1300px){
    .services-cont{
        margin-top: 10vh;
        margin-bottom: 10vh;
    }
}

.services-home{
    font-size: 30px;
    margin-bottom: 5vh;
}

.card-serv{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    border-radius: 10px;
    padding: 1vh;
    margin-top: 1vh;
    font-size: 20px;
    line-height: 50px;
    height: 60px;
    overflow-y: hidden;
    transition: ease-in all 0.5s;
}

.card-serv-exp{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    border-radius: 10px;
    padding: 1vh;
    margin-top: 1vh;
    font-size: 20px;
    line-height: 50px;
    height: 125px;
    transition: ease-in all 0.5s;
    overflow-y: hidden;
}

.card-serv-exp p{
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 2%;
}

.card-serv-exp:hover {
    box-shadow: 0 16px 32px 0 rgba(0,0,0,0.2);
    color: red;
    cursor: pointer;
}

.down-arrow{
    position: absolute;
    right: 0;
    margin-right: 18vw;
}

.down-arrow-act{
    position: absolute;
    right: 0;
    margin-right: 18vw;
    rotate: 180deg;
}

.card-serv p{
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 2%;
}

.card-serv:hover {
    box-shadow: 0 16px 32px 0 rgba(0,0,0,0.2);
    color: red;
    cursor: pointer;
}

@media screen and (max-width: 750px){
    .card-serv{
        font-size: 15px;
    }
    .card-serv-exp{
        font-size: 15px;
        height: 150px;
    }
    .card-serv p{
        font-size: 12px;
    }
    .card-serv-exp p{
        font-size: 12px;
    }
}

@media screen and (max-width: 750px) and (orientation: landscape){
    .card-serv-exp{
        height: 125px;
    }
}

@media screen and (min-width: 1000px){
    .services-cont{
        margin-left: 20%;
        margin-right: 20%;
    }
    .down-arrow,
    .down-arrow-act{
        margin-right: 23vw;
    }

}

@media screen and (min-width: 1300px){
    .services-cont{
        margin-left: 32%;
        margin-right: 32%;
    }
    .down-arrow,
    .down-arrow-act{
        margin-right: 33vw;
    }
}