.contact-cont{
margin-top: 10vh;
margin-left: 15%;
margin-right: 15%;
line-height: 25px;
}

@media screen and (max-width: 750px) and (orientation: landscape){
    .contact-cont{
        font-weight: 100;
    }
}
@media screen and (max-width: 500px){
    .contact-cont{
        font-weight: 100;
    }
}

@media screen and (orientation: landscape){
    .contact-cont{
        margin-top: 10vw;
    }
}

@media screen and (min-width: 1000px){
    .contact-cont{
        margin-left: 20%;
        margin-right: 20%;
    }
}

@media screen and (min-width: 1300px){
    .contact-cont{
        margin-left: 32%;
        margin-right: 32%;
    }
}

/*------------------------------------------------------*/
/*--------------------Contact Form----------------------*/
/*------------------------------------------------------*/

.contact-form-cont {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.3);
    border-radius: 10px;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
}

@media screen and (max-width: 750px){
    .contact-form-cont{
        height: 400px;
    }
}

@media screen and (orientation: portrait){
    .contact-form-cont {
        margin-top: 7vh;
        margin-bottom: 13vh;
    }
}

@media screen and (orientation: landscape){
    .contact-form-cont {
        margin-top: 7vw;
        margin-bottom: 13vw;
    }
}

@media screen and (min-width: 1300px){
    .contact-form-cont{
        margin-top: 7vh;
        margin-bottom: 13vh;
    }
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 90%;
}

.contact-form input {
    font-family: 'Open Sans';
    height: 2.5rem;
    border-width: 2px;
    font-size: 2.5vw;
    padding: 0.5rem;
}

.contact-form textarea {
font-family: 'Open Sans';
    height: 7rem;
    border-width: 2px;
    font-size: 2.5vw;
    padding: 0.5rem;
    resize: none;
}

.contact-form button {
    font-family: 'Open Sans';
    height: 3rem;
    border: none;
    border-width: 2px;
    font-size: 2.5vw;
    font-weight: bold;
    padding: 0.5rem;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    background-color: rgba(255, 0, 0, 0.5);
    color: black;
}

.contact-form button:hover {
    transition: 0.4s ease-in-out;
    background-color: red;
}

@media screen and (min-width: 1000px){
    
.contact-form {
    width: 90%;
}
.contact-form input {
    font-size: 20px;
}

.contact-form textarea {
    font-size: 20px;
}

.contact-form button {
    font-size: 20px;
}
}

@media screen and (max-width: 750px){
    .contact-form{
        width: 90%
    }
    .contact-form input {
        font-size: 15px;
    }
    
    .contact-form textarea {
        font-size: 15px;
    }
    
    .contact-form button {
        font-size: 15px;
    }
}

@media screen and (max-width: 750px) and (orientation: landscape){
    .contact-form{
        width: 90%
    }
}

@media screen and (max-width: 500px)
{
    .contact-form input {
        font-size: 3.5vw;
    }
    
    .contact-form textarea {
        font-size: 3.5vw;
    }
    
    .contact-form button {
        font-size: 3.5vw;
    }
}