.about-cont{
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 10vh;
    margin-bottom: 12vh;
    line-height: 25px;
}

@media screen and (orientation: landscape){
    .about-cont{
        margin-top: 10vw;
        margin-bottom: 12vw;
    }
}

.graph-paper{
    position: absolute;
    z-index: -1;
    height: 95vh;
    width: 88.5vw;
    opacity: 0.4;
    margin-left: -10vw;
    margin-top: -7vh;
}

.little-house{
    position: absolute;
    z-index: -1;
    opacity: 0.3;
    width: 50vw;
    margin-top: 33vh;
    margin-left: 31.5vw;
}

@media screen and (min-width: 1001px){
    .about-cont{
        margin-left: 32%;
        margin-right: 32%;
    }
    .graph-paper{
        width: 70vw;
        margin-left: -15vw;
        height: 800px;
    }
    .little-house{
        margin-top: 330px;
        width: 300px;
        margin-left: 24vw;
    }
}

@media screen and (min-width: 1100px){
    .graph-paper{
        height: 730px;
    }
    .little-house{
        width: 30vw;
        margin-top: 300px;
        margin-left: 22vw;
    }
}

@media screen and (min-width: 1200px){
    .little-house{
        margin-top: 250px;
    }
}

@media screen and (min-width: 1100px){
}

@media screen and (max-width: 1000px){
    .graph-paper{
        margin-left: -10vw;
        height: 640px;
    }
    .little-house{
        margin-top: 200px;
        margin-left: 29vw;
    }
}

@media screen and (max-width: 815px){
    .graph-paper{
        height: 725px;
    }
    .little-house{
        margin-top: 250px;
        margin-left: 30vw;
    }
}

@media screen and (max-width: 700px){
    .graph-paper{
        height: 700px;
    }
    .little-house{
        display: none;
    }
}

@media screen and (max-width: 600px){
    .graph-paper{
        height: 730px;
    }
}

@media screen and (max-width: 550px){
    .graph-paper{
        height: 770px;
    }
}

@media screen and (max-width: 500px){
    .graph-paper{
        display: none;
    }
    .about-cont{
        font-weight: 100;
    }
}

@media screen and (orientation:landscape) and (max-width: 750px){
    .graph-paper{
        display: none;
    }
    .little-house{
        display: none;
    }
    .about-cont{
        font-weight: 100;
    }
}



