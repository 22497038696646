header a {
  text-decoration: none;
  color: black;
}

header a:hover {
  color: red;
  text-decoration: none;
  transition: all 0.5s ease;
}

.red-cont {
  text-align: left;
  background-color: white;
  color: red;
  font-weight: bolder;
  font-size: 2.6vw;
  height: 14vw;
  align-items: center;
  margin-bottom: 2vw;
}

.r-e-d {
  display: flex;
  align-items: center;
  font-size: 6.5vw;
  border-width: 4px;
  margin-left: 5%;
}

.architectural-drafting {
  display: flex;
  width: 36vw;
  margin-left: 5%;
  align-items: center;
  border-top: solid;
  border-color: red;
  border-width: 3px;
}

@media screen and (max-width: 750px){
  .red-cont{
    font-size: 19.5px;
    height: 105px;
  }
  .r-e-d{
    font-size: 48.75px;
  }
  .architectural-drafting{
    width: 290px;
  }
}

.header-routes {
  display: grid;
  grid-template-columns: repeat(4, auto);
  text-align: center; 
  font-weight: bolder;
  font-size: 2.6vw;
  height: 7vw;
  align-items: center;
  background-color: white;
}

.home {
  color: black;
  text-shadow: 1px 1px 2px black;
}

.about {
    color: black;
    text-shadow: 1px 1px 2px black;}
.services{
    color: black;
    text-shadow: 1px 1px 2px black;
}

    .contact {
    color: black;
    text-shadow: 1px 1px 2px black;
}

@media screen and (max-width: 750px){
  .header-routes{
    font-size: 19.5px;
    height: 52.5px;
  }
  .home, .about, .services, .contact{
    text-shadow: 0.5px 0.5px 1px black;
  }
}

@media screen and (min-width: 1000px){
  .red-cont {
    font-size: 26px;
    height: 140px;
    margin-bottom: 2vw;
  }
  
  .r-e-d {
    font-size: 65px;
    margin-left: 5%;
  }

  .architectural-drafting {
    width: 350px;
    border-width: 3px;
  }
  .header-routes {
    font-size: 26px;
    height: 70px;
  }
}