* {
    box-sizing: border-box;
    margin: 0;
}

:root {
    --heights: 75vh;
    --widths: 100%;
}

@media screen and (max-width: 750px) and (orientation: landscape){
:root {
  --heights: 100vh;
}
}

@media screen and (min-width: 1000px){
  :root{
    --heights: 90vh;
  }
}

@media screen and (min-width: 1300px){
  :root{
    --widths: 60vw;
  }
}


.slider-container{
    height: var(--heights);
    width: var(--widths);
    position: relative;
    margin: auto;
    overflow: hidden;
    margin-top: 7vh;
    margin-bottom: 10vh;
}

@media screen and (orientation: landscape){
  .slider-container{
    margin-bottom: 10vw;
  }
}

@media screen and (min-width: 1300px){
  .slider-container{
      margin-bottom: 10vh;
  }
}

.active{
    display: inline-block;
}

.inactive{
    display: none;
}

.slides {
    height: var(--heights);
    width: var(--widths);
    position: relative;
}

.slide-image{
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
}

.slide-title,
.slide-text{
    width: 60vw;
    margin-left: 20vw;
    height: 10vw;
    color: white;
    background-color: rgba(0,0,0,0.6);
    font-size: 7vw;
    position: absolute;
    text-align: center;
    top: 43.5%;
    z-index: 10;
}

.slide-text{
    margin-left: 27.5vw;
    width: 45vw;
    height: 6vw;
    top: 63%;
    font-size: 4vw;
}

@media screen and (max-width: 1000px){
  .slide-text{
    top: 70%; 
  }
}

@media screen and (max-width: 750px){
  .slide-title,
.slide-text{
  margin-left: 15vw;
    width: 70vw;
    top: 47%;
}

.slide-text{
  top: 65%;
  width: 50vw;
  margin-left: 25vw;
  height: 30px;
  font-size: 20px;
}
}

@media screen and (max-width: 750px) and (orientation: landscape){
  .slide-title{
    top: 43%;
  }
  .slide-text{
   top: 68%;
   font-size: 25px;
   height: 35px;
   width: 40vw;
   margin-left: 30vw;
  }
}

@media screen and (min-width: 1000px){
  .slide-title,
.slide-text{
    font-size: 70px;
    height: 100px;
}

.slide-text{
    height: 60px;
    font-size: 40px;
}
}
@media screen and (min-width: 1300px){
  .slide-title,
.slide-text{
    width: 35vw;
    margin-left: 13vw;
    height: 5.5vw;
    font-size: 3.5vw;
}

.slide-text{
    margin-left: 15.5vw;
    width: 30vw;
    height: 4vw;
    top: 65%;
    font-size: 2.5vw;
}
}
.prev,
.next{
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 40px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
    background-color: gray;
}

.prev:hover,
.next:hover {
    color: white;
    background-color: rgba(0,0,0,0.6);
    transition: all 0.5s ease-in;
}

.next {
    right: 0;
    border-radius: 5px 0px 0px 5px;
}

.all-dots {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    top: 90%;
    justify-content: center;
    z-index: 200;
}

.dot {
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0px 3px;
    border-radius: 50%;
    display: inline-block;
}

.dot-active-dot {
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    margin: 0px 3px;
    background-color: rgba(0,0,0,0.6);
    border-radius: 50%;
    display: inline-block;
}

.all-dots span:hover {
    background-color: rgba(0,0,0,0.6);
}

/*   additional    */

* {box-sizing:border-box}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}