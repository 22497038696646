
.footer-cont{
    background-color: white;
    margin-left: 15%;
    margin-bottom: 0vh;
    margin-top: 5vh;
    line-height: 25px;
}

@media screen and (orientation: landscape){
    .footer-cont{
        margin-top: 5vw;
    }
}

.ratri1{
    position: absolute;
    height: 13vw;
    rotate: 90deg; 
    left: 0;
    margin-left: -3vw;
    margin-top: -3.2vw;
}

@media screen and (max-width: 750px){
    .ratri1{
        height: 97.5px;
        margin-left: -22.5px;
        margin-top: -24px;
    }
}

@media screen and (min-width: 1000px){
    .ratri1{
        height: 130px;
        margin-top: -32px;
    }
    .footer-contact-info{
        font-size: 20px;
        line-height: 35px;
    }
    .footer-cont{
        margin-top: 3vw;
    }
}

.red-line{
    border-color: red;
}

.footer-contact-info{
    margin-top: 5vh;
    margin-bottom: -1vh;
}

.copyright-cont{
    font-size: 12px;
    margin-left: -15vw;
    margin-bottom: 1.5vh;
    text-align: center;
}

.web-design-link {
    text-decoration: none;
    color: black;
  }
  
  .web-design-link:hover {
    color: red;
    transition: all 0.5s ease;
  }

  @media screen and (max-width: 750px){
    .copyright-cont{
        font-size: 1.75vw;
    }
  }
  
  @media screen and (max-width: 750px) and (orientation: landscape){
    .footer-cont{
        font-size: 10px;
    }
    
    .copyright-cont{
        font-size: 7px;
    }
  }